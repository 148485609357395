import Select from 'react-select';
import useAuth from 'hooks/useAuth';
import baseController from 'api/base.controller';
import { Form, Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import FieldValidationMessage from '../../sistema/fieldValidationMessage';

export default function EventForm({ data, setData, handleData, validated }) {
  const { auth } = useAuth();
  const [valuePessoa, setValuePessoa] = useState(null);
  const [comboValuesPessoa, setComboValuesPessoa] = useState([]);

  useEffect(async () => {
    const pessoaGetAll = await baseController.get('Pessoa/GetAll', {
      headers: {
        Authorization: auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    });

    setComboValuesPessoa(adjustPessoaDataToCombo(pessoaGetAll.data));

    if(data.pessoaResponsavel !== null)
      setValuePessoa(adjustPessoaSingleDataToCombo(data.pessoaResponsavel));
  }, []);

  const adjustPessoaDataToCombo = (data) => {
    return data.map((pessoa) => {
      return adjustPessoaSingleDataToCombo(pessoa);
    });
  };

  const adjustPessoaSingleDataToCombo = (data) => {
    return {
      value: data.id,
      nome: data.nome,
      label: data.nome
    };
  };
  
  useEffect(() => {
    const pessoaId = valuePessoa?.value;
    const newData = {...data};
    
    newData['pessoaResponsavel'] = null;
    newData['pessoaResponsavelId'] = pessoaId;

    setData(newData);
  }, [valuePessoa]);

  return (
    <>
      <Row className="g-2 mb-2">
        <Col>
          <Form.Group controlId="formGroupName">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              required
              name="nome"
              type="text"
              minLength={2}
              maxLength={100}
              value={data.nome}
              autoComplete="no"
              placeholder="Nome do Evento"
              onChange={(e) => handleData(e, "input")}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={2}
                maxLength={100}
                fieldName="Nome" 
              />
            </Form.Control.Feedback>
          </ Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="formGroupLocal">
            <Form.Label>Local</Form.Label>
            <Form.Control
              required
              name="local"
              type="text"
              minLength={20}
              maxLength={150}
              value={data.local}
              autoComplete="no"
              placeholder="Local do Evento"
              onChange={(e) => handleData(e, "input")}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={20}
                maxLength={150}
                fieldName="Local" 
              />
            </Form.Control.Feedback>
          </ Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formGroupTipoEvento">
            <Form.Label>Tipo de Evento</Form.Label>
            <Form.Select
              required
              name="tipo"
              value={data.tipo}
              aria-label="Tipo do Evento"
              onChange={(e) => handleData(e, "select")}
            >
              <option value="T">Ticket</option>
              <option value="C">Cashless Pós-Pago</option>
              <option value="P">Cashless Pré-Pago</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="g-2 mb-5">
        <Col>
          <Form.Group controlId="formGroupDataInicio">
            <Form.Label>Data de Início</Form.Label>
            <Form.Control
              required
              name="dataInicio"
              type="datetime-local"
              value={data.dataInicio}
              selected={data.dataInicio}
              placeholder="Data de Início do Evento"
              onChange={(e) => handleData(e, "date")}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage fieldName="Data de Início" />
            </Form.Control.Feedback>
          </ Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formGroupDataTermino">
            <Form.Label>Data de Encerramento</Form.Label>
            <Form.Control
              required
              name="dataTermino"
              type="datetime-local"
              value={data.dataTermino}
              selected={data.dataTermino}
              onChange={(e) => handleData(e, "date")}
              placeholder="Data de Encerramento do Evento"
              isInvalid={new Date(data.dataTermino).getTime() < new Date(data.dataInicio).getTime()}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage 
                greaterThan
                fieldName="Data de Encerramento"
                fieldGreaterThan="Data de Início"
              />
            </Form.Control.Feedback>
          </ Form.Group>
        </Col>
      </Row>
      <Row className="g-1">
        <Col>
          <Form.Group controlId="formGroupPessoaResponsavelId">
            <Form.Label>Cliente Responsável</Form.Label>
            <Select
              required
              value={valuePessoa}
              closeMenuOnSelect={true}
              name='pessoaResponsavelId'
              options={comboValuesPessoa}
              classNamePrefix='react-select'
              placeholder='Selecione a pessoa'
              onChange={(valuePessoa) => { setValuePessoa(valuePessoa) }}
              className={validated && valuePessoa === null ? 'form-control is-invalid' : ''}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage fieldName={"Pessoa"} />
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}