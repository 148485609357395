import Select from 'react-select';
import useAuth from 'hooks/useAuth';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import baseController from 'api/base.controller';
import React, { useState, useEffect } from 'react';
import icmsOrigemData from './Fiscal/icmsOrigemData';
import UploadField from '../sistema/formUploadField';
import { produtoRoles } from '../sistema/allowedRoles';
import Error401 from 'components/pages/errors/Error401';
import { useNavigate, useParams } from "react-router-dom";
import FullScreenLoading from '../sistema/fullScreenLoading';
import toastDefaultOptions from '../sistema/toastDefaultOptions';
import pisSituacaoTributariaData from './Fiscal/pisSituacaoTributaria';
import FieldValidationMessage from '../sistema/fieldValidationMessage';
import icmsSituacaoTributariaData from './Fiscal/icmsSituacaoTributaria';
import cofinsSituacaoTributariaData from './Fiscal/cofinsSituacaoTributaria';
import { Col, Row, Card, Form, Button, Modal, InputGroup } from 'react-bootstrap';
import { defaultErrorMessage, tryAgainMessage } from '../sistema/messageConsts';

export default function ProdutoEdit({ isModal, handleModalSubmit, setModalShow, className }) {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [files, setFiles] = useState((<></>));
  const [loading, setLoading] = useState(false);
  const [valueLoja, setValueLoja] = useState(null);
  const [validated, setValidated] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [comboLojaValues, setComboLojaValues] = useState([]);
  const [modalFiscalShow, setModalFiscalShow] = useState(false);
  const [fiscalDataValidated, setFiscalDataValidated ] = useState(false);

  const [icmsOrigem, setIcmsOrigem] = useState(null);
  const [pisSituacaoTributaria, setPisSituacaoTributaria] = useState(null);
  const [icmsSituacaoTributaria, setIcmsSituacaoTributaria] = useState(null);
  const [cofinsSituacaoTributaria, setCofinsSituacaoTributaria] = useState(null);

  const [isCliente] = useState(["CM-C", "CM-A"].includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role));

  const [fiscalData, setFiscalData] = useState({
    cfop: undefined,
    codigoNcm: undefined,
    codigoCest: undefined,
    icmsOrigem: undefined,
    icmsSituacaoTributaria: undefined,
    icmsPorcentagem: undefined,
    pisSituacaoTributaria: undefined,
    pisPorcentagem: undefined,
    cofinsSituacaoTributaria: undefined,
    cofinsPorcentagem: undefined
  });
  const [data, setData] = useState({
    id: "",
    preco: 0,
    nome: "",
    imagem: "",
    lojaId: undefined,
    fileName: "",
    formFile: "",
    descricao: "",
    precoPorKg: false,
    produtoFiscal: undefined
  });

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*'
  });

  const adjustLojaDataToCombo = (data) => {
    return data.map((loja) => {
      return adjustUniqueLojaDataToShow(loja);
    });
  };
  
  const adjustUniqueLojaDataToShow = (loja) => {
    return {
      value: loja.id,
      nome: loja.nome,
      label: loja.nome,
    }
  };

  const getData = async () => {
    setLoading(true);

    baseController.get(`Produto/${id}`, {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    })
    .then(async res => {
      if (!res.data.produtoFiscal)
        res.data.produtoFiscal = null;
      else
        setFiscalData(res.data.produtoFiscal);

      setData(res.data);

      if (res.data.produtoFiscal?.icmsOrigem)
        setIcmsOrigem(
          icmsOrigemData.filter(value => value.value === (res.data.produtoFiscal.icmsOrigem))[0]
        );

      if (res.data.produtoFiscal?.pisSituacaoTributaria)
        setPisSituacaoTributaria(
          pisSituacaoTributariaData.filter(value => value.value === (res.data.produtoFiscal.pisSituacaoTributaria))[0]
        );

      if (res.data.produtoFiscal?.icmsSituacaoTributaria)
        setIcmsSituacaoTributaria(
          icmsSituacaoTributariaData.filter(value => value.value === (res.data.produtoFiscal.icmsSituacaoTributaria))[0]
        );

      if (res.data.produtoFiscal?.cofinsSituacaoTributaria)
        setCofinsSituacaoTributaria(
          cofinsSituacaoTributariaData.filter(value => value.value === (res.data.produtoFiscal.cofinsSituacaoTributaria))[0]
        );

      const lojas = await baseController.get('Loja/GetAll', {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      });
      
      setComboLojaValues(() => {
        const value = adjustLojaDataToCombo(lojas.data);

        if (res.data.lojaId)
          setValueLoja(
            value.filter(value => value.value === (res.data.lojaId))[0]
          );

        return value;
      });
    })
    .catch((error) => toast.error(`${defaultErrorMessage}${error?.message ?? ""}${tryAgainMessage}`, toastDefaultOptions))
    .finally(() => setLoading(false));
  };

  const handleConfirm = (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    if(!form.checkValidity()) {
      event.stopPropagation();

      setFiscalDataValidated(true);

      return;
    }

    const newData = {...data};

    newData.produtoFiscal = fiscalData;
    
    setData(newData);
    setModalFiscalShow(false);
  };

  useEffect(async () => {
    const isAuthorized = produtoRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if(isAuthorized && id && !isModal) getData();

    if(isCliente && !id) {
      const newData = { ...data };

      try {
        const defaultLoja = await baseController.get('Loja/GetId/ClienteMensal', {
          headers: {
            'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
          }
        });

        newData.lojaId = defaultLoja.data.id;

        setData(newData);
      } catch (error) {
        toast.error(`${defaultErrorMessage}${error?.response?.data?.Message ?? ""}${tryAgainMessage}`, toastDefaultOptions);
      }
    }
  }, []);

  useEffect(() => {
    const newData = { ...data };
    const reader = new FileReader();

    acceptedFiles.map(file => {
      reader.onloadend = function() {
        const filteredBase64 = reader.result.replace(/(data[a-zA-Z0-9:/;].+?base64,)/g, "");

        newData['fileName'] = file.name;
        newData['formFile'] = filteredBase64;

        setData(newData);

        setFiles((
          <li key={file.path}>
            <img src={reader.result}  height='100' />
          </li>));
      };
      
      reader.readAsDataURL(file);
    });
  }, [acceptedFiles]);

  useEffect(() => {
    const newData = {...fiscalData};

    if (newData)
      newData.icmsOrigem = icmsOrigem?.value;

    setFiscalData(newData);
  }, [icmsOrigem]);

  useEffect(() => {
    const newData = {...fiscalData};

    if (newData)
      newData.pisSituacaoTributaria = pisSituacaoTributaria?.value;

    setFiscalData(newData);
  }, [pisSituacaoTributaria]);

  useEffect(() => {
    const newData = {...fiscalData};

    if (newData)
      newData.icmsSituacaoTributaria = icmsSituacaoTributaria?.value;

    setFiscalData(newData);
  }, [icmsSituacaoTributaria]);

  useEffect(() => {
    const newData = {...fiscalData};

    if (newData)
      newData.cofinsSituacaoTributaria = cofinsSituacaoTributaria?.value;

    setFiscalData(newData);
  }, [cofinsSituacaoTributaria]);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const form = event.currentTarget;

    if(!form.checkValidity()) {
      event.stopPropagation();

      setValidated(true);
      setLoading(false);

      return;
    }

    if (isModal) {
      baseController.post('Produto', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then((res) => handleModalSubmit(event, res.data))
        .catch((error) => toast.error(`${defaultErrorMessage}${error?.response?.data?.Message ?? ""}${tryAgainMessage}`, toastDefaultOptions))
        .finally(() => setLoading(false));
    }
    else {
      id ? 
        baseController.put('Produto', data, {
          headers: {
            'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
          }
        })
          .then(() => navigate(-1))
          .catch((error) => toast.error(`${defaultErrorMessage}${error?.response?.data?.Message ?? ""}${tryAgainMessage}`, toastDefaultOptions))
          .finally(() => setLoading(false)) : 
        baseController.post('Produto', data, {
          headers: {
            'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
          }
        })
          .then(() => navigate(-1))
          .catch((error) => { console.log(error); toast.error(`${defaultErrorMessage}${error?.response?.data?.Message ?? ""}${tryAgainMessage}`, toastDefaultOptions)})
          .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    const newData = {...data};

    newData.lojaId = valueLoja?.value;

    setData(newData);
  }, [valueLoja])

  const handleFiscalData = (event, type) => {
    const newData = {...fiscalData}

    switch (type) {
      case 'number': {
        const value = parseFloat(event.target.value);

        newData[event.target.name] = value;

        if(value <= 0) {
          event.target.setCustomValidity(" ");
          event.target.reportValidity();
        } else event.target.setCustomValidity("");

        break;
      }
      default: {
        newData[event.target.name] = event.target.value;

        break;
      }
    }

    setFiscalData(newData);
  };
  
  const handleData = (event, type) => {
    const newData = {...data};
    const splittedName = event.target.name.split('.');
    const splitLength = splittedName.length;

    switch(type) {
      case 'switch': {
        newData[event.target.name] = event.target.checked;
        break;
      }
      case 'number': {
        const value =  parseFloat(event.target.value);

        if(splitLength === 2)
          newData[splittedName[0]][splittedName[1]] = value;
        else
          newData[event.target.name] = value;

        if(value <= 0) {
          event.target.setCustomValidity(" ");
          event.target.reportValidity();
        } else event.target.setCustomValidity("");

        break;
      }
      default: {
        if(splitLength === 2)
          newData[splittedName[0]][splittedName[1]] = event.target.value;
        else
          newData[event.target.name] = event.target.value;
        
        break;
      }
    }
    
    setData(newData);
  };

  if(loading) return (<FullScreenLoading />);

  if(!authorized) return ( <Error401 />);

  return(
    <Card className={className ? className + 'edit-card' : 'edit-card'}>
      <Card.Header>
        <h1 className='edit-form-title'>
          { id && !isModal ? 'Editar produto' : 'Incluir produto'}
        </h1>
      </Card.Header>
      <Card.Body
        as={Form}
        noValidate
        id='produto-form'
        validated={validated}
        onSubmit={handleSubmit}
        className='edit-card-body'
      >
        <Row>
          <Form.Group 
            className="mb-3" 
            controlId="formGroupName"
          >
            <Form.Label>Nome</Form.Label>
            <Form.Control
              required
              name="nome"
              type="text"
              minLength={2}
              maxLength={100}
              value={data.nome}
              placeholder="Nome do produto"
              onChange={(e) => handleData(e)}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={2}
                maxLength={100}
                fieldName={"Nome"}
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group 
            className="mb-3" 
            controlId="formGroupDescricao"
          >
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              rows={4}
              required
              type="text"
              as="textarea"
              minLength={2}
              maxLength={400}
              name="descricao"
              value={data.descricao}
              onChange={(e) => handleData(e)}
              placeholder="Descrição do produto"
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={2}
                maxLength={400}
                fieldName={"Descrição"}
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="formGroupPreco"
          >
            <Form.Label>Preço</Form.Label>
            <InputGroup>
              <InputGroup.Text>R$</InputGroup.Text>
              <Form.Control
                required
                name="preco"
                type="number"
                value={data.preco ?? 0}
                placeholder="0,00"
                onChange={(e) => handleData(e, 'number')}
              />
              <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                fieldName={"Preço"}
                overridenMessage={"O campo 'Preço' deve ser maior do que 0."}
              />
            </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group 
            className="mb-3" 
            controlId="formGroupImagem"
          >
            <Form.Label>Imagem</Form.Label>
            <UploadField
              files={files}
              required={!id && !data.formFile}
              multiple={false}
              fieldName="Imagem"
              imgUrl={data.imagem}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              acceptedFiles={acceptedFiles}
            />
          </Form.Group>
          <Form.Group 
            className="mb-3" 
            controlId="formGroupPrecoPorKg"
          >
            <Form.Label>Preço por Kg?</Form.Label>
            <Form.Check
              type='switch'
              id='precoPorKg'
              name='precoPorKg'
              checked={data.precoPorKg}
              onChange={(e) => handleData(e, "switch")}
            />
          </Form.Group>
          <Form.Group 
            controlId="formLojaId"
            className="mb-3 form-group-select-with-button"
          >
            <Form.Label>Loja</Form.Label>
            <Select
              name="lojaId"
              isMulti={false}
              value={valueLoja}
              closeMenuOnSelect
              options={comboLojaValues}
              classNamePrefix="react-select"
              placeholder="Selecione a loja"
              onChange={value => setValueLoja(value)}
              className={validated && data.lojaId === null ? 'form-control is-invalid select-loja-event-form' : 'select-pessoas-loja-form'}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                fieldName={"Loja"}
              />
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <Button
              variant='primary'
              className='edit-save-button'
              onClick={() => setModalFiscalShow(true)}
            >
              Configurar Dados Fiscais
            </Button>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="edit-buttons-wrapper">
        <Button 
          variant="danger"
          onClick={() => {
            !isModal ? navigate(-1) : setModalShow(false);
          }}
        >
          Cancelar
        </Button>
        <Button 
          type="submit"
          variant="primary"
          form="produto-form"
          className="edit-save-button"
        >
          Salvar
        </Button>
      </Card.Footer>
      <Modal
        centered
        fullscreen
        show={modalFiscalShow}
        onHide={() => setModalFiscalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          closeButton
        >
          <Modal.Title
            className="select-menu-modal-title"
            id="contained-modal-title-vcenter"
          >
            Dados Fiscais do Produto
          </Modal.Title>
        </Modal.Header>
        <Modal.Body 
          as={Form}
          noValidate
          id="fiscal-data-form"
          validated={fiscalDataValidated}
          onSubmit={(event) => handleConfirm(event)}
          className="cardapio-select-modal-body config-store-modal-form"
        >
          <Row>
            <Col>
              <Form.Group 
                className="mb-3" 
                controlId="formGroupCfop"
              >
                <Form.Label>Código CFOP</Form.Label>
                <Form.Control
                  required
                  type="text"
                  minLength={4}
                  maxLength={4}
                  name="cfop"
                  placeholder="Código CFOP"
                  onChange={(e) => handleFiscalData(e)}
                  value={fiscalData.cfop}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage
                    exactSize={4}
                    fieldName={"Código CFOP"}
                  />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group 
                className="mb-3" 
                controlId="formGroupNcm"
              >
                <Form.Label>Código NCM</Form.Label>
                <Form.Control
                  required
                  type="text"
                  minLength={8}
                  maxLength={8}
                  placeholder="Código NCM"
                  name="codigoNcm"
                  onChange={(e) => handleFiscalData(e)}
                  value={fiscalData.codigoNcm}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage
                    exactSize={8}
                    fieldName={"Código NCM"}
                  />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group 
                className="mb-3" 
                controlId="formGroupCest"
              >
                <Form.Label>Código CEST</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Código CEST"
                  onChange={(e) => handleFiscalData(e)}
                  name="codigoCest"
                  value={fiscalData.codigoCest}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage
                    fieldName={"Código CEST"}
                  />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupIcmsOrigem">
                <Form.Label>ICMS Origem</Form.Label>
                <Select
                  value={icmsOrigem}
                  closeMenuOnSelect={true}
                  options={icmsOrigemData}
                  classNamePrefix='react-select'
                  name='icmsOrigem'
                  placeholder='Selecione a Origem'
                  onChange={(value) => setIcmsOrigem(value)}
                  className={fiscalDataValidated && icmsOrigem === null ? 'form-control is-invalid select-icms-origem-form' : 'select-icms-origem-form'}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage fieldName={"ICMS Origem"} />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupIcmsIcmsSituacaoTributaria">
                <Form.Label>ICMS Situação Tributária</Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  classNamePrefix='react-select'
                  value={icmsSituacaoTributaria}
                  options={icmsSituacaoTributariaData}
                  name='icmsSituacaoTributaria'
                  placeholder='Selecione a situação tributária do ICMS'
                  onChange={(value) => setIcmsSituacaoTributaria(value)}
                  className={fiscalDataValidated && icmsSituacaoTributaria === null ? 'form-control is-invalid select-icms-st-form' : 'select-icms-st-form'}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage fieldName={"ICMS Situação Tributária"} />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="formGroupIcmsPercentual"
              >
                <Form.Label>ICMS</Form.Label>
                <InputGroup>
                  <InputGroup.Text>%</InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="0,00"
                    name="icmsPorcentagem"
                    onChange={(e) => handleFiscalData(e, 'number')}
                    value={fiscalData.icmsPorcentagem}
                  />
                  <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage
                    fieldName={"ICMS"}
                  />
                </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="formGroupPisSituacaoTributaria">
                <Form.Label>PIS Situação Tributária</Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  value={pisSituacaoTributaria}
                  classNamePrefix='react-select'
                  options={pisSituacaoTributariaData}
                  name='pisSituacaoTributaria'
                  placeholder='Selecione a situação tributária do PIS'
                  onChange={(value) => setPisSituacaoTributaria(value)}
                  className={fiscalDataValidated && pisSituacaoTributaria === null ? 'form-control is-invalid select-pis-st-form' : 'select-pis-st-form'}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage fieldName={"PIS Situação Tributária"} />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="formGroupPisPercentual"
              >
                <Form.Label>PIS</Form.Label>
                <InputGroup>
                  <InputGroup.Text>%</InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="0,00"
                    name="pisPorcentagem"
                    onChange={(e) => handleFiscalData(e, 'number')}
                    value={fiscalData.pisPorcentagem}
                  />
                  <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage
                    fieldName={"PIS"}
                  />
                </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="formGroupCofinsSituacaoTributaria">
                <Form.Label>Cofins Situação Tributária</Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  classNamePrefix='react-select'
                  value={cofinsSituacaoTributaria}
                  options={cofinsSituacaoTributariaData}
                  name='cofinsSituacaoTributaria'
                  placeholder='Selecione a situação tributária do Cofins'
                  onChange={(value) => setCofinsSituacaoTributaria(value)}
                  className={fiscalDataValidated && pisSituacaoTributaria === null ? 'form-control is-invalid select-cofins-st-form' : 'select-cofins-st-form'}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage fieldName={"Cofins Situação Tributária"} />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="formGroupCofinsPercentual"
              >
                <Form.Label>Cofins</Form.Label>
                <InputGroup>
                  <InputGroup.Text>%</InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="0,00"
                    name="cofinsPorcentagem"
                    onChange={(e) => handleFiscalData(e, 'number')}
                    value={fiscalData.cofinsPorcentagem}
                  />
                  <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage
                    fieldName={"Cofins"}
                  />
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              {/* Teste */}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
        <Button 
          variant="danger"
          onClick={() => { setModalFiscalShow(false); }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="primary"
          form="fiscal-data-form"
          className="edit-save-button"
        >
          Salvar
        </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  )
}