import useAuth from 'hooks/useAuth';
import FilterFields from './filterFields';
import DateColumn from '../sistema/dateColumn';
import baseController from 'api/base.controller';
import { Row, Col, Card } from 'react-bootstrap';
import ReloadButton from '../sistema/reloadButton';
import InsertButton from '../sistema/insertButton';
import React, { useEffect, useState } from 'react';
import FilterButton from '../sistema/filterButton';
import ActionsColumn from '../sistema/actionsColumn';
import Error401 from 'components/pages/errors/Error401';
import FullScreenLoading from '../sistema/fullScreenLoading';
import { editRoles, eventoRoles } from '../sistema/allowedRoles';
import AdvanceTable from '../../common/advance-table/AdvanceTable';
import CalculateRowQuantity from '../sistema/calculateRowQuantity';
import { emptyMessage, errorMessage } from '../sistema/gridMessages';
import AdvanceTableWrapper from '../../common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from '../../common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from '../../common/advance-table/AdvanceTablePagination';

export default function Evento() {
  let timeout;
  window.onresize = () => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      CalculateRowQuantity(itemsPerPage, setItemsPerPage)
    }, 500);
  };

  const { auth } = useAuth();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isSlow, setIsSlow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [authorized, setAuthorized] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [message, setMessage] = useState(emptyMessage);
  const [specificFilter, setSpecificFilter] = useState({
    nome: "",
    local: "",
    tipoEvento: "",
    dataInicioInicial: "",
    dataInicioFinal: "",
    dataTerminoInicial: "",
    dataTerminoFinal: "",
  });

  const clearFilterValues = () => {
    setSpecificFilter({
      nome: "",
      local: "",
      tipoEvento: "",
      dataInicioInicial: "",
      dataInicioFinal: "",
      dataTerminoInicial: "",
      dataTerminoFinal: ""
    });
  };

  const goToPage = (page) => {
    setPage(page)
  };

  const columns = [
    {
      accessor: 'nome',
      Header: 'Nome'
    },
    {
      accessor: 'local',
      Header: 'Local do Evento'
    },
    {
      accessor: 'tipo',
      Header: 'Tipo de Evento',
      Cell: (rowData) => {
        const { tipo } = rowData.row.original,
        keyValue = {
          T: "Ticket",
          C: "Cashless Pós-Pago",
          P: "Cashless Pré-Pago"
        };

        return (<>{keyValue[tipo]}</>);
      }
    },
    {
      accessor: 'dataInicio',
      Header: 'Início do Evento',
      Cell: (rowData) => {
        const { dataInicio } = rowData.row.original;

        return (
          <DateColumn 
            showTime
            date={dataInicio}
          />
        )
      }
    },
    {
      accessor: 'dataTermino',
      Header: 'Término do Evento',
      Cell: (rowData) => {
        const { dataTermino } = rowData.row.original;

        return (
          <DateColumn 
            showTime
            date={dataTermino} 
          />
        )
      }
    },
    {
      accessor: 'id',
      Header: 'Ações',
      Actions: true,
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <ActionsColumn 
            showDetail
            showDashboard
            getData={getData}
            showEdit={canEdit}
            showDelete={canEdit}
            setLoaded={setLoaded}
            deleteRoute={`Evento/${id}`}
            controller={baseController}
            editRoute={`/evento/edit/${id}`}
            detailRoute={`/evento/detail/${id}`}
            detailGridData={rowData.row.original}
            dashboardRoute={`/evento/dashboard/${id}`}
          />
        );
      },
      cellProps:{
        style: {
          width: '125px'
        }
      }
    }
  ];

  const getData = async () => {
    setData([]);
    setLoaded(false);

    const timeoutGetData = setTimeout(() => {
      setIsSlow(true);
    }, 5000);

    if(itemsPerPage > 0)
      baseController.get('Evento', {
        params: {
          page,
          itemsPerPage,
          filterValue: globalFilter,
          specificFilter
        },
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
      .then((res) => {
        setData(res.data.eventos);
        setTotalPages(res.data.totalPages);
        
        if(res.data.eventos.length === 0)
          setMessage(emptyMessage);

        if(res.data.totalPages < page) {
          goToPage(1);
          setCurrentPage(1);
        }
      })
      .catch(() => setMessage(errorMessage))
      .finally(() => {
        setLoaded(true);
        setIsSlow(false);
        clearTimeout(timeoutGetData);
      });
  };

  useEffect(() => {
    getData();
  }, [page, globalFilter, itemsPerPage]);

  useEffect(() => {
    const roleToValidate = auth.role ?? JSON.parse(localStorage.getItem('auth')).role

    const canEdit = editRoles.includes(roleToValidate);
    const isAuthorized = eventoRoles.includes(roleToValidate);

    setCanEdit(canEdit);
    setAuthorized(isAuthorized);

    if(isAuthorized)
      CalculateRowQuantity(itemsPerPage, setItemsPerPage);
  }, []);

  if(!loaded) return (<FullScreenLoading isSlow={isSlow} />);

  if(!authorized) return (<Error401 />);

  return (
    <Card className='grid-card'>
      <Card.Header>
        <h1 className='index-table-title'>
          Eventos
        </h1>
      </Card.Header>
      <Card.Body className='table-card-body'>
        <AdvanceTableWrapper
          sortable
          pagination
          columns={columns}
          data={data ?? []}
          perPage={itemsPerPage ? itemsPerPage : 10}
        >
          <Row className="flex-end-center mb-3">
            <Col>
              <div className='grid-actions'>
                { canEdit ?
                  <InsertButton route='/evento/edit' /> :
                  <></>
                }
                <ReloadButton getData={getData} />
                <FilterButton
                  getData={getData}
                  FilterFields={FilterFields}
                  specificFilter={specificFilter}
                  setSpecificFilter={setSpecificFilter}
                  clearFilterValues={clearFilterValues}
                />
              </div>
            </Col>
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                placeholder = 'Digite sua pesquisa aqui'
              />
            </Col>
          </Row>
          <AdvanceTable
            table
            rowClassName="align-middle white-space-nowrap"
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          { !data.length ?
            <div className='mt-3 table-no-data'>
              <p>{message}</p>
            </div>
            : <></>
          }
          <div className='table-paginator-wrapper'>
            <div className="mt-3">
              <AdvanceTablePagination 
                setPage={setPage}
                goToPage={goToPage}
                pageCount={totalPages}
                pageIndex={currentPage}
                setCurrentPage={setCurrentPage} 
              />
            </div>
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
}