
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import { PieChart } from 'echarts/charts';
import { Card, Modal } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormatCurrency from 'components/pages/sistema/formatCurrency';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import DetailIcon from "../../../../assets/img/icons/request-system/detail.svg";
import generateRandomHexColor from 'components/pages/sistema/randomColorGenerator';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import LoadingAnimation from 'components/pages/sistema/loadingAnimation';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency", 
  currency: "BRL"
});

const getOptions = (data, radius) => {
  const total = formatter.format(data.reduce((soma, item) => soma + item.value, 0));

  return ({
    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params =>
        `<strong>${params.data.name}:</strong> ${params.percent}%`
    },
    series: [
      {
        name: total,
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: total < 1000000 ? 23 : 
                    total < 10000000 ? 19 : 16,
          color: getColor('dark')
        },
        data
      }
    ]
  });
}

const VendasProduto = ({ data, showDetails }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState({});

  data = data.map((item) => {
    return {
      value: item.total,
      lojas: item.lojas,
      name: item.produtoNome,
      itemStyle: {
        color: generateRandomHexColor()
      }
    }
  });

  const VendasProdutoList = ({ data }) => {
    return (
      <Row className="g-3 font-sans-serif">
        <Col sm={12}>
          <div className="d-flex align-items-center mb-4">
            <h6 className="mb-0 fw-bold">Lojas</h6>
          </div>
          <div className="rounded-3 p-3 vendas-justify">
            
            <ul className="fill-available-width list-unstyled mb-0">
              {data.map((item, index) => (
                <li
                  key={item.name}
                  className={`li-dashboard-with-button d-flex fs--2 fw-medium pt-1 align-center ${
                    index !== data.length - 1 && 'mb-3'
                  }`}
                >
                  <div className='align-center flex-row'>
                    <FontAwesomeIcon
                      icon="circle"
                      className='me-2'
                      color={item.itemStyle?.color}
                    />
                    <p className="lh-sm mb-0 text-700 vendas-text">
                      {item.name}
                    </p>
                    <p className="lh-sm mb-0 text-700 vendas-text">
                      <span className="text-900 ps-2"><FormatCurrency number={item.value} /></span>
                    </p>
                  </div>
                  { showDetails ? 
                    <Button 
                      title='Detalhes'
                      onClick={() => { setShowModal(true); setCurrentItem(item); }}
                      className='detail-vendas-produto button-in-dashboard'
                    >
                      <img src={DetailIcon} className='button-icon'/>
                    </Button> : <></>
                  }
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    );
  };
  
  const VendasProdutoChart = ({ data }) => {
    const chartRef = useRef(null);
  
    return (
      <div className="position-relative py-2">
        <BasicECharts
          ref={chartRef}
          echarts={echarts}
          style={{ minHeight: '18.75rem' }}
          option={getOptions(data, ['70%', '100%'])}
        />
      </div>
    );
  };

  const modalColumns = [
    {
      accessor: 'nome',
      Header: 'Loja'
    },
    {
      accessor: 'total',
      Header: 'Receita',
      Cell: rowData => {
        const { total } = rowData.row.original;

        return <FormatCurrency number={total} />
      }
    }
  ];

  return (
    <Card className="h-100 dashboard-card-shadow">
      <Card.Body className="d-flex flex-column justify-content-between">
        <h4 className="mt-1">Produtos Mais Vendidos</h4>
        { data.length <= 0 ?
          <LoadingAnimation /> :
          <>
            <VendasProdutoChart data={data} />
            <VendasProdutoList data={data} />
          </>
        }  
      </Card.Body>

      <Card.Footer className="bg-light py-2 d-flex">
        <div className="ms-auto">
        </div>
      </Card.Footer>
      <Modal
        centered
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Detalhamento de vendas do produto por loja
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AdvanceTableWrapper
            sortable
            pagination
            perPage={10}
            columns={modalColumns}
            data={currentItem?.lojas ?? []}
          >
            <AdvanceTable
              table
              rowClassName="align-middle white-space-nowrap"
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </AdvanceTableWrapper>
        </Modal.Body>
      </Modal>
    </Card>
  );
};

export default VendasProduto;