import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function DownloadPDFButton({generatePDF}) {
    return (
        <Button 
            title='Download PDF'
            onClick={generatePDF}
            className='pdf-download-button-index dashboard'
        >
          <FontAwesomeIcon icon='file-pdf' />
        </Button>
    );
}