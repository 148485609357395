import React, { useRef } from 'react';
import { Card } from 'react-bootstrap';
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import { PieChart } from 'echarts/charts';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormatCurrency from 'components/pages/sistema/formatCurrency';
import DashboardButton from 'components/pages/sistema/dashboardButton';
import generateRandomHexColor from 'components/pages/sistema/randomColorGenerator';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency", 
  currency: "BRL"
});

const getOptions = (data, radius) => {
  const total = formatter.format(data.reduce((soma, item) => soma + item.value, 0));

  return ({
    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params =>
        `<strong>${params.data.name}:</strong> ${params.percent}%`
    },
    series: [
      {
        name: total,
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: total < 1000000 ? 23 : 
                    total < 10000000 ? 19 : 16,
          color: getColor('dark')
        },
        data
      }
    ]
  });
}

const VendasLoja = ({ data }) => {
  data = data.map((item) => {
    return {
      id: item.lojaId,
      value: item.total,
      name: item.lojaNome,
      itemStyle: {
        color: generateRandomHexColor()
      }
    }
  });

  const VendasLojaList = ({ data }) => {
    const { id } = useParams();

    return (
      <Row className="g-3 font-sans-serif">
        <Col sm={12}>
          <div className="d-flex align-items-center mb-4">
            <h6 className="mb-0 fw-bold">Lojas</h6>
          </div>
          <div className="rounded-3 p-3 vendas-justify">
            
            <ul className="fill-available-width list-unstyled mb-0">
              {data.map((item, index) => (
                <li
                  key={item.name}
                  className={`li-dashboard-with-button d-flex fs--2 fw-medium pt-1 align-center ${
                    index !== data.length - 1 && 'mb-3'
                  }`}
                >
                  <div className='align-center flex-row'>
                    <FontAwesomeIcon
                      icon="circle"
                      className='me-2'
                      color={item.itemStyle?.color}
                    />
                    <p className="lh-sm mb-0 text-700 vendas-text">
                      {item.name}
                      <span className="text-900 ps-2"><FormatCurrency number={item.value} /></span>
                    </p>
                  </div>
                  <DashboardButton 
                    aditionalClasses="button-in-dashboard"
                    route={`/evento/dashboard/${id}/${item.id}`}
                  />
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    );
  };
  
  const VendasLojaChart = ({ data }) => {
    const chartRef = useRef(null);
  
    return (
      <div className="position-relative py-2">
        <BasicECharts
          ref={chartRef}
          echarts={echarts}
          style={{ minHeight: '18.75rem' }}
          option={getOptions(data, ['70%', '100%'])}
        />
      </div>
    );
  }
  return (
    <Card className="h-100 dashboard-card-shadow">
      <Card.Body className="d-flex flex-column justify-content-between">
        <h4 className="mt-1">Vendas por Loja</h4>
        <VendasLojaChart data={data} />
        <VendasLojaList data={data} />   
      </Card.Body>

      <Card.Footer className="bg-light py-2 d-flex">
        <div className="ms-auto">
        </div>
      </Card.Footer>
    </Card>
  );
};

export default VendasLoja;