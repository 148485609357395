import React from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DateColumn from 'components/pages/sistema/dateColumn';

const HomeEvento = ({ data, isLojaEvento, isClienteMensal }) => {
  const navigate = useNavigate();

  const handleClick = (evento) => {
    if (isLojaEvento || isClienteMensal)
      navigate(`/evento/dashboard/${evento.id}/${evento.lojaId}`);
    else 
      navigate(`/evento/dashboard/${evento.id}`);
  }
  
  return (
    <Row className="g-3 mb-3 home-card-grid">
      {data.map(evento => (
        <Col key={evento.id + evento.lojaId}>
          <Card className='overflow-hidden dashboard-card-shadow home-card'>
            <Card.Header className='evento-card-header'>
              <OverlayTrigger
                placement='top'
                overlay={(<Tooltip
                  id={'tooltip-top'}
                  style={{ position: 'fixed' }}
                >
                  {evento.nome + ' - '+ evento.local}
                </Tooltip>)}
              >
                <h4 className='home-evento-card-title d-inline-block'>
                  {`${evento.nome} ${evento.local}`}
                </h4>
              </OverlayTrigger>
              { isLojaEvento ?
                <OverlayTrigger
                  placement='bottom'
                  overlay={(<Tooltip
                    style={{ position: 'fixed' }}
                  >
                    {evento.lojaNome}
                  </Tooltip>)}
                >
                  <h5>
                    {evento.lojaNome}
                  </h5>
                </OverlayTrigger>
                  : <></>
              }
            </Card.Header>
            { !isClienteMensal ?
              <Card.Body className="position-relative evento-card-body">
                <h5>
                  Data Início:
                  <DateColumn date={evento.dataInicio} showTime />
                </h5>
                <h5>
                  Data Termino:
                  <DateColumn date={evento.dataTermino} showTime />
                </h5>
              </Card.Body> : <></>
            }
            <Card.Footer className='evento-card-footer' onClick={() => handleClick(evento)}>
              <p>Acessar Dashboard</p>
            </Card.Footer>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default HomeEvento;