import { v4 as uuid } from 'uuid';
import Select from 'react-select';
import useAuth from 'hooks/useAuth';
import { useDropzone } from 'react-dropzone';
import baseController from "api/base.controller";
import React, { useState, useEffect } from "react";
import CreateSellPointModal from './createSellPointModal';
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import CardapioEdit from "components/pages/cardapio/edit";
import UploadField from 'components/pages/sistema/formUploadField';
import FullScreenLoading from "components/pages/sistema/fullScreenLoading";
import FieldValidationMessage from 'components/pages/sistema/fieldValidationMessage';

export default function SelectMenuModal({ id, data, setData }) {
  const { auth } = useAuth();
  const [loaded, setLoaded] = useState(false);
  const [files, setFiles] = useState((<></>));
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [pontoVendas, setPontoVendas] = useState([]);
  const [cardapioData, setCardapioData] = useState(null);
  const [temporaryMenu, setTemporaryMenu] = useState({});
  const [cardapioValue, setCardapioValue] = useState(null);
  const [cardapioModalShow, setCardapioModalShow] = useState(false);
  const [cardapioComboValues, setCardapioComboValues] = useState([]);
  const [formaPagamentoValue, setFormaPagamentoValue] = useState([]);
  const [pagamentoIntegradoValue, setPagamentoIntegradoValue] = useState(false);
  const [formaPagamentoComboValues, setFormaPagamentoComboValues] = useState([]);
  const [createSellPointModalShow, setCreateSellPointModalShow] = useState(false);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ accept: 'image/*' });

  const defaultInfoAdicionais = {
    lojaId: id,
    gerarNFCe: true,
    imagemTicket: "",
    modoImpressao: 'P',
    validaQRCode: false,
    imprimirPreco: true,
    informacoesAdicionais: ""
  };

  const [informacoesAdicionaisData, setInformacoesAdicionaisData] = useState(defaultInfoAdicionais);

  const adjustDataToCombo = (data) => {
    return data.map((item) => {
      return {
        value: item.id,
        nome: item.nome,
        label: item.nome,
        padrao: item.padrao ?? null,
        categorias: item.categorias ?? [],
        integravel: item.integravel ?? null,
        produtoFiscal: item.produtoFiscal ?? {}
      }
    })
  };

  const setDefaultValuesFormaPagamento = (formas) => {
    if(!data.eventoLojaFormaPagamentos.filter(item => item.lojaId === id).length) {
      const defaultValues = [];

      formas.forEach(formaPagamento => {
        if (formaPagamento.padrao)
          defaultValues.push(formaPagamento)
      });

      setFormaPagamentoValue(defaultValues);
    }
  };

  const handlePagamentoIntegradoData = (event) => {
    setPagamentoIntegradoValue(event.target.checked);
  };

  const handleCreateSellPointsModal = () => {
    setCreateSellPointModalShow(true);
  };

  const handleCreateSellPoints = (event, quantity) => {
    event.preventDefault();
    event.stopPropagation();

    const newData = [...pontoVendas];
    const currentLength = newData.length;
    const lengthToUse = currentLength + parseInt(quantity);

    for (let count = (currentLength + 1); count <= lengthToUse; count++) {
      newData.push({
        nome: `Caixa ${count < 10 ? '0' + count : count}`,
        lojaId: id,
      })
    }

    setPontoVendas(newData);
    setCreateSellPointModalShow(false);
  };

  const handleDataInformacoesAdicionais = (event, type) => {
    const newData = { ...informacoesAdicionaisData };

    if (type === 'switch')
      newData[event.target.name] = event.target.checked;
    else newData[event.target.name] = event.target.value;

    setInformacoesAdicionaisData(newData);
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if(!event.currentTarget.checkValidity() || !pontoVendas || !formaPagamentoValue || !temporaryMenu) {
      setValidated(true);
      return;
    }

    const newData = { ...data };

    newData.eventoLojaInformacoesAdicionais = newData.eventoLojaInformacoesAdicionais.filter(elia => elia.lojaId !== id);
    newData.eventoLojaInformacoesAdicionais.push(informacoesAdicionaisData);
    setInformacoesAdicionaisData(defaultInfoAdicionais);

    const sameCardapio = newData.eventoLojaCardapios.find(elc => elc.lojaId === id);

    if(!sameCardapio) newData.eventoLojaCardapios.push(temporaryMenu);
    else if(cardapioValue) {
      const index = newData.eventoLojaCardapios.indexOf(sameCardapio);
      newData.eventoLojaCardapios.splice(index, 1);

      newData.eventoLojaCardapios.push(temporaryMenu);
    }
    setCardapioValue(null);

    newData.eventoLojaFormaPagamentos = newData.eventoLojaFormaPagamentos.map(elfp => {
      if(id === elfp.lojaId) {
        return {
          ...elfp,
          pagamentoIntegrado: pagamentoIntegradoValue && elfp.formaPagamento.integravel
        }
      } else return elfp;
    });

    const elfpToRemove = newData.eventoLojaFormaPagamentos.filter(original => !formaPagamentoValue.some(current => current.value === original.formaPagamentoId) && original.lojaId === id);
    elfpToRemove.forEach(item => {
      newData.eventoLojaFormaPagamentos = newData.eventoLojaFormaPagamentos.filter(value => (value.formaPagamentoId !== item.formaPagamentoId && value.lojaId === id) || value.lojaId !== id);
    });

    const elfpToAdd = formaPagamentoValue.filter(c => !newData.eventoLojaFormaPagamentos.some(o => o.formaPagamentoId === c.value && o.lojaId === id));
    elfpToAdd.forEach(item => {
      newData.eventoLojaFormaPagamentos.push({
        lojaId: id,
        formaPagamentoId: item.value,
        pagamentoIntegrado: pagamentoIntegradoValue && item.integravel,
        formaPagamento: {
          id: item.value,
          nome: item.label,
          integravel: item.integravel
        }
      });
    });

    setFormaPagamentoValue([]);

    const elpvToAdd = pontoVendas.filter(current => !newData.eventoLojaPontoVendas.some(original => original.nome === current.nome && original.lojaId === current.lojaId));
    elpvToAdd.forEach(item => {
      newData.eventoLojaPontoVendas.push(item);
    });
    setPontoVendas([]);

    setData(newData);

    ensureDefaultValues();
  };

  const handleCardapioModalSubmit = async (event, cardapio) => {
    setLoaded(false);
    event.preventDefault();

    const newCardapioData = cardapioData;
    newCardapioData.push(cardapio);

    setCardapioData(newCardapioData);
    setCardapioModalShow(false);
    setLoaded(true);
  };

  const ensureDefaultValues = () => {
    setValidated(false);

    setPontoVendas([]);
    setTemporaryMenu({});
    setCardapioData(null);
    setCardapioValue(null);
    setCardapioComboValues([]);
    setFormaPagamentoValue([]);
    setPagamentoIntegradoValue(false);
    setFormaPagamentoComboValues([]);
  }

  useEffect(async () => {
    if (modalShow) {
      try {
        const headers = { 'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token };

        if(!cardapioComboValues.length) {
          const resCardapios = await baseController.get(`Cardapio/GetByLojaId/${id}`, { headers });
          setCardapioComboValues(adjustDataToCombo(resCardapios.data));
        }
        
        if(!formaPagamentoComboValues.length) {
          const resFormaPagamentos = await baseController.get('FormaPagamento/GetAll', { headers });
          const formaPagamentoValues = adjustDataToCombo(resFormaPagamentos.data);
          setFormaPagamentoComboValues(formaPagamentoValues);
          setDefaultValuesFormaPagamento(formaPagamentoValues);
        }

        setPontoVendas(data.eventoLojaPontoVendas.filter(item => item.lojaId === id));

        const formasPagamento = data.eventoLojaFormaPagamentos.filter(item => item.lojaId === id);

        if(formasPagamento.length > 0)
          setFormaPagamentoValue(adjustDataToCombo(formasPagamento.map((elfp) => { return elfp.formaPagamento })));
        setPagamentoIntegradoValue(formasPagamento.find((elfp) => elfp.pagamentoIntegrado));

        const infosAdicionais = data.eventoLojaInformacoesAdicionais.filter(item => item.lojaId === id)[0] ?? defaultInfoAdicionais;
        setInformacoesAdicionaisData(infosAdicionais);
      } catch (e) { console.log(e.message ?? e); }

      setLoaded(true);
    }
  }, [modalShow]);

  useEffect(() => {
    setCardapioValue(cardapioComboValues.find(combo => combo.value === data.eventoLojaCardapios.filter(item => item.lojaId === id)[0]?.cardapioId));
  }, [cardapioComboValues]);

  useEffect(() => {
    const newData = { ...informacoesAdicionaisData };
    const reader = new FileReader();

    acceptedFiles.map(file => {
      reader.onloadend = function () {

        const filteredBase64 = reader.result.replace(/(data[a-zA-Z0-9:/;].+?base64,)/g, "");

        newData['fileName'] = file.name;
        newData['formFile'] = filteredBase64;

        setInformacoesAdicionaisData(newData);

        setFiles((
          <li key={file.path}>
            <img src={reader.result} height='85' />
          </li>
        ));
      };

      reader.readAsDataURL(file);
    });
  }, [acceptedFiles]);

  useEffect(() => {
    if(cardapioValue)
      setTemporaryMenu(doCreateCardapio());
  }, [cardapioValue]);

  const doCreateCardapio = () => {
    const cardapioId = uuid();
    const categorias = cardapioValue.categorias.map(categoria => {
      const categoriaId = uuid();

      return {
        id: categoriaId,
        cardapioId,
        nome: categoria.exibicao,
        exibicao: categoria.exibicao,
        produtos: categoria.produtos.map(produto => {
          return {
            id: uuid(),
            nome: produto.nome,
            preco: produto.preco,
            imagem: produto.imagem,
            categoriaId: categoriaId,
            descricao: produto.descricao,
            precoPorKg: produto.precoPorKg,
            produtoFiscal: produto.produtoFiscal,
            produtoFiscalId: produto.produtoFiscalId
          };
        })
      };
    });

    return {
      id: cardapioId,
      lojaId: id,
      categorias
    };
  };

  return (
    <>
      <Button
        title="Configurar Loja"
        onClick={() => {
          setModalShow(true);
        }}
      >
        Configurar Loja
      </Button>
      <Modal
        centered
        size="lg"
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="select-menu-modal-title"
            id="contained-modal-title-vcenter"
          >
            Configuração de Loja
          </Modal.Title>
        </Modal.Header>
        <Modal.Body 
          as={Form}
          id="config-menu-form"
          onSubmit={handleConfirm}
          className="cardapio-select-modal-body config-store-modal-form"
        >
          {loaded ?
            <>
              <Row>
                <Form.Group controlId='formPontoVendas'>
                  <div className={(!pontoVendas || pontoVendas.length <= 0) && validated ? 'form-control is-invalid select-loja-event-form' : 'select-pessoas-loja-form'}>
                    <Row>
                      <Col>
                        <Button
                          className='create-sell-points'
                          onClick={handleCreateSellPointsModal}
                        >
                          Criar Pontos de Venda
                        </Button>
                        <CreateSellPointModal
                          modalShow={createSellPointModalShow}
                          setModalShow={setCreateSellPointModalShow}
                          handleCreateSellPoints={handleCreateSellPoints}
                        />
                      </Col>
                      <Col>
                        {pontoVendas.length > 0 ? (<p>{pontoVendas.length} Caixas criados</p>) : <p>Nenhum caixa criado</p>}
                      </Col>
                    </Row>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    <FieldValidationMessage
                      overridenMessage="É obrigatório criar ao menos um Ponto de Venda"
                    />
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  controlId="formCardapioId"
                  className="mb-3 form-group-select-with-button select-in-modal"
                >
                  <Form.Label>Cardápio</Form.Label>
                  <Select
                    isMulti={false}
                    name="cardapioId"
                    closeMenuOnSelect
                    value={cardapioValue}
                    style={{ marginTop: '10px' }}
                    options={cardapioComboValues}
                    classNamePrefix="react-select"
                    placeholder='Selecione o cardápio'
                    className={'select-pessoas-loja-form'}
                    onChange={value => setCardapioValue(value)}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  controlId="formGroupFormaPagamento"
                  className="mb-3 form-group-select-with-button select-in-modal"
                >
                  <Form.Label>Formas de Pagamento</Form.Label>
                  <Select
                    isMulti
                    name="formasPagamento"
                    closeMenuOnSelect={false}
                    value={formaPagamentoValue}
                    style={{ marginTop: '10px' }}
                    classNamePrefix="react-select"
                    options={formaPagamentoComboValues}
                    placeholder='Selecione as Formas de Pagamento'
                    onChange={value => setFormaPagamentoValue(value)}
                    className={(!formaPagamentoValue || formaPagamentoValue.length <= 0) && validated ? 'form-control is-invalid select-loja-event-form' : 'select-pessoas-loja-form'}
                  />
                  <Form.Control.Feedback type="invalid">
                    <FieldValidationMessage
                      fieldName={"Formas de Pagamento"}
                    />
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="formGroupPagamentoIntegrado">
                  <Form.Label>Pagamento Integrado?</Form.Label>
                  <Form.Check
                    type='switch'
                    id='pagamentoIntegrado'
                    name='pagamentoIntegrado'
                    checked={pagamentoIntegradoValue}
                    onChange={(e) => handlePagamentoIntegradoData(e)}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="formGroupImagemTicket">
                  <Form.Label>Imagem do Ticket</Form.Label>
                  <UploadField
                    files={files}
                    multiple={false}
                    getRootProps={getRootProps}
                    fieldName="Imagem do Ticket"
                    getInputProps={getInputProps}
                    acceptedFiles={acceptedFiles}
                    imgUrl={informacoesAdicionaisData.imagemTicket}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="formGroupInformacoesAdicionais">
                  <Form.Label>Informações Adicionais</Form.Label>
                  <Form.Control
                    rows={5}
                    type="text"
                    as="textarea"
                    minLength={2}
                    maxLength={400}
                    name="informacoesAdicionais"
                    placeholder="Informações Adicionais do Ticket"
                    value={informacoesAdicionaisData.informacoesAdicionais}
                    onChange={(e) => handleDataInformacoesAdicionais(e, "input")}
                  />
                </ Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="formGroupModoImpressao">
                  <Form.Label>Modo de Impressão</Form.Label>
                  <Form.Select
                    name="modoImpressao"
                    aria-label="Modo de Impressão"
                    value={informacoesAdicionaisData.modoImpressao}
                    onChange={(e) => handleDataInformacoesAdicionais(e, "select")}
                  >
                    <option value="P">Ficha Padrão</option>
                    <option value="A">Ficha Agrupada</option>
                    <option value="S">Ficha Agrupada com Senha</option>
                  </Form.Select>
                </Form.Group>
              </Row>

              <Row>
                <Col>
                  <Form.Label>Imprimir Preço?</Form.Label>
                  <Form.Group controlId="formGroupImprimirPreco">
                    <Form.Check
                      disabled
                      aria-disabled
                      type='switch'
                      id='imprimirPreco'
                      name='imprimirPreco'
                      checked={informacoesAdicionaisData.imprimirPreco}
                      onChange={(e) => handleDataInformacoesAdicionais(e, "switch")}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>Validar QR Code?</Form.Label>
                  <Form.Group controlId="formGroupValidaQRCode">
                    <Form.Check
                      disabled
                      aria-disabled
                      type='switch'
                      id='validaQRCode'
                      name='validaQRCode'
                      checked={informacoesAdicionaisData.validaQRCode}
                      onChange={(e) => handleDataInformacoesAdicionais(e, "switch")}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>Gerar NFCe?</Form.Label>
                  <Form.Group controlId="formGroupGerarNFCe">
                    <Form.Check
                      type='switch'
                      id='gerarNFCe'
                      name='gerarNFCe'
                      checked={informacoesAdicionaisData.gerarNFCe}
                      onChange={(e) => handleDataInformacoesAdicionais(e, "switch")}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
            :
            <FullScreenLoading />
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            title="Cancelar"
            variant="danger"
            onClick={() => { ensureDefaultValues(); setModalShow(false); } }
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            title="Confirmar"
            form="config-menu-form"
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        size="lg"
        show={cardapioModalShow}
        onHide={() => setCardapioModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <CardapioEdit
          lojaId={id}
          isModal={true}
          className="form-in-modal "
          setModalShow={setCardapioModalShow}
          handleCardapioModalSubmit={(event, data) => handleCardapioModalSubmit(event, data)}
        />
      </Modal>
    </>
  )
}